import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Absolute,
  Button,
  TextInput,
  PseudoBox,
  Link,
  Text
} from "pws-design-system/design-system";
import { navigate } from "gatsby";
import _isNil from "lodash.isnil";
import aeris from "../../../../api/";
import Locations from "../../../../models/aeris/place/Locations";
import Stations from "../../../../models/aeris/place/Stations";
import AutoSuggest from "../../../common/components/AutoSuggest";
import { Link as ReachLink } from "gatsby";
import { Z } from "../../../../types/enums";
import { messageStore } from "../../../common/hooks/useMessages";
import { ThemeContainer } from "../../../common/hooks/useTheme";

function SearchResults({ results }): React.ReactElement {
  if (_isNil(results) === true) {
    return null;
  }
  const hasResults = Object.keys(results).filter(key => results[key].hasData);
  return (
    <Absolute
      top="47px"
      left="18px"
      bg="white"
      borderColor="rgba(0,0,0,0.8)"
      width="450px"
      height="250px"
      borderRadius="0 0 10px 10px"
      borderWidth={8}
      borderStyle="solid"
      // borderTop="0px solid transparent"
    >
      <Flex
        width="100%"
        justify="space-between"
        align="flex-start"
        overflowY="auto"
        maxHeight="calc(100% - 43px)"
      >
        {Object.keys(results).map((key, i) => (
          <>
            {results[key].hasData === true && (
              <Box width={`${100 / hasResults.length}%`} ml={i !== 0 && 2}>
                <Box>
                  <Text
                    mb={1}
                    px={2}
                    py={1}
                    bg="brand.green.base"
                    color="white"
                    variant="label"
                    fontSize="12px"
                  >
                    {key}
                  </Text>
                </Box>
                {results[key].models.map(model => (
                  <Link
                    width="100%"
                    href={`/${model.placeType === "location" ? "local" : "station"}/${model.url}`}
                    // as={ReachLink}
                  >
                    <PseudoBox
                      px={2}
                      py={2}
                      borderBottomColor="brand.gray.300"
                      borderBottomWidth="1px"
                      borderBottomStyle="solid"
                      _hover={{
                        bg: "brand.gray.100",
                        cursor: "pointer"
                      }}
                    >
                      <Text fontSize="md">{model.displayId}</Text>
                    </PseudoBox>
                  </Link>
                ))}
              </Box>
            )}
          </>
        ))}
      </Flex>
    </Absolute>
  );
}
export default function SearchField(): React.ReactElement {
  const [query, setQuery] = useState(null);
  const [results, setResults] = useState(null);
  const [isHidingResults, setIsHidingResults] = useState(false);
  const locationsApi = aeris.api().endpoint("places/search");
  const stationsApi = aeris.api().endpoint("observations/search");
  const { theme } = ThemeContainer.useContainer();

  useEffect(() => {
    if (_isNil(query) === false && query.length > 2) {
      async function fetchPlaces() {
        const locationsRequest = locationsApi
          .resetParams()
          .param(`query`, `name:^${query.toUpperCase()};name:^${query.toLowerCase()}`)
          .limit(50)
          .from("-10days")
          .filter("metar;pws;madis;ausbom");
        const stationsRequest = stationsApi
          .resetParams()
          .param(
            `query`,
            `id:^${query.toUpperCase()},datasource:!MADIS_METAR,datasource:!MADIS_HFMETAR`
          )
          .limit(50)
          .from("-10days")
          .filter("metar;pws;madis;ausbom,allownosky");

        const [locationsResponse, stationsResponse] = await Promise.all([
          locationsApi.get(),
          stationsApi.get()
        ]);
        const locations = new Locations({
          data: locationsResponse.data
        });
        const stations = new Stations({
          data: stationsResponse.data
        });
        setResults({ locations, stations });
      }
      fetchPlaces();
    }
  }, [query]);

  function handleFocus() {}

  function handleBlur() {
    setTimeout(() => {
      setResults(null);
    }, 500);
  }

  function handleChange(event: React.SyntheticEvent) {
    const target = event.target as HTMLInputElement;
    setQuery(target.value);
  }

  function handleClick(event: React.SyntheticEvent) {
    navigate(`/map/`);
  }

  return (
    <Absolute bg="rgba(0,0,0,0.8)" anchor="top" mt={3} p={2} rounded="full" zIndex={Z.autosuggest}>
      <Flex>
        <Box width={400} mx="-10px">
          <AutoSuggest expandedWidth={400} theme={theme} />
        </Box>
        <Button onClick={handleClick} height={"40px"} ml={2}>
          {messageStore.autosuggest_station_map_link_text}
        </Button>
      </Flex>
    </Absolute>
  );
}
