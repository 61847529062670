import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";
import UsePreviewDataStoreContainer from "../../common/hooks/stores/usePreviewDataStore";
import _isNil from "lodash.isnil";

export default function useQuery(): {
  homepage: {};
  mainNavigation: {};
} {
  const [storedData] = UsePreviewDataStoreContainer.useContainer();
  // debugger;
  // if (previewData !== null) {
  //   return {
  //     homepage: previewData.pageData,
  //     mainNavigation: previewData.bundledData.mainNavigation
  //   };
  // }
  const data = useStaticQuery(
    graphql`
      query {
        ...Homepage
        ...MainNavigation
      }
    `
  );
  if (_isNil(storedData) !== true) {
    const previewData = {
      homepage: storedData.pageData.prismicHomepage.data,
      mainNavigation: storedData.bundledData.mainNavigation
    };

    return previewData;
  }

  return Object.keys(data).reduce(
    (prev, curr) => {
      const item = data[curr];
      const itemData = _get(item, "edges[0].node.data", null);
      (prev as any)[curr] = itemData;
      return prev;
    },
    { homepage: null, mainNavigation: null }
  );
}
