import InteractiveMap from "@aerisweather/javascript-sdk/dist/maps/interactive/InteractiveMap";
import VectorSource from "@aerisweather/javascript-sdk/dist/maps/interactive/sources/VectorSource";
import { css } from "@emotion/react";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import {
  Absolute,
  Box,
  Button,
  ButtonGroup,
  Col,
  Flex,
  Grid,
  Heading,
  Hide,
  Logo,
  Row,
  Section
} from "pws-design-system/design-system";
import React, { useEffect, useState } from "react";
import aeris from "../../../api/";
import Observation from "../../../models/aeris/observation";
import { buildStationPath } from "../../../models/path";
import HomepageModel from "../../../models/prismic/homepage";
import Link, { LinkDisplayRuleEnum } from "../../../models/prismic/link";
import User from "../../../models/pws/User";
import ClientRender from "../../common/components/client-render";
import Map, {
  obsFields,
  stationMarkerConfigurator
} from "../../common/components/map/MapController";
import MapPanel from "../../common/components/map/MapPanel";
import UnitToggle from "../../common/components/unit-toggle";
import { UnitStoreContainer } from "../../common/hooks/stores/useUnitStore";
import { messageStore, useMessages } from "../../common/hooks/useMessages";
import useUser from "../../common/hooks/useUser";
import { ThemeContainer } from "../../common/hooks/useTheme";
import PageLayout from "../../common/layouts/PageLayout";
import HomeLogoLink from "../place/LogoHomeLink/";
import Blocks from "./blocks";
import SearchField from "./map/SearchField";
import useQuery from "./useQuery";
import { toBounds } from "@aerisweather/javascript-sdk/dist/utils/strings";
import { Providers } from "../../common/layouts/Layout";

const zoomDistMap: IZoomDistMap = {
  4: 275000,
  5: 130000,
  6: 75000,
  7: 35000,
  8: 20000,
  9: 10000,
  10: 5000
};

const setupMap = (map: InteractiveMap) => {
  const base = 20;

  const stations = new VectorSource("station-obs", {
    requiresBounds: true,
    data: {
      url: (params: any): string => {
        const zoom = map.getZoom();
        const minDist = zoomDistMap[zoom] ? zoomDistMap[zoom] : base * Math.pow(2, 18 - zoom);

        const bounds = map.strategy.getBounds();
        const request = aeris
          .api()
          .endpoint("observations")
          .resetParams()
          .action("within")
          .param("mindist", `${minDist}m`)
          .place(toBounds(bounds))
          .filter("metar;pws;madis;ausbom,allownosky,precise")
          .limit(500)
          .sort("dt:-1")
          .fields(obsFields.join(","));

        return request.url();
      },
      properties: {
        root: "response"
      }
    },
    style: {
      marker: (data: any): any => {
        return stationMarkerConfigurator(data, map, "temps");
      }
    }
  });
  map.addSource(stations, "station-obs");
};

const HeroBackground = (): React.ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "pws-landing-header.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          durationFadeIn={50}
          fluid={imageData}
          css={css`
            height: 100%;
            width: 100%;
            background-position: center 0;
          `}
        />
      );
    }}
  />
);

interface HomepageProps {
  path: RouteComponentProps;
}

interface IZoomDistMap {
  [key: number]: number;
}

enum ButtonVariant {
  Primary = "solid",
  Secondary = "outline"
}
enum ButtonVariantColor {
  Primary = "brand",
  Secondary = "light"
}

type ButtonVariantKey = "Primary" | "Secondary";

const HomepageMap = ({ defaultZoom }: any): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const [mapRef, setMapRef] = useState();
  const [activeObservation, setActiveObservation] = useState();
  const [selectedMarker, setSelectedMarker] = useState();
  const { units, unitType } = UnitStoreContainer.useContainer();

  const api = aeris.api();
  api._paramKeys.push("mindist");

  const selectMarker = (markerData: any, map: InteractiveMap) => {
    setSelectedMarker((prev: any) => {
      if (prev && prev.marker) {
        map.strategy.updateMarker(prev.marker, {
          style: stationMarkerConfigurator(prev.data, map, false)
        });
      }
      return markerData;
    });
  };

  // setup map event handlers when loaded
  useEffect(() => {
    if (mapRef) {
      const map = mapRef;
      setupMap(map);

      map.on("marker:click", (e: any) => {
        const eventData = e.data || {};
        const data = eventData.data;
        const source = data.awxjs_source;

        if (source === "station-obs") {
          // update selected marker state
          const marker = eventData.marker;
          if (marker) {
            selectMarker(eventData, map);
          }
          if (data) {
            const observation = new Observation({ data });
            setActiveObservation(observation);
          }
        } else {
          setActiveObservation(null);
          selectMarker(null, map);
        }
      });
    }
  }, [mapRef]);

  // deselect selected marker when active obs is unset
  useEffect(() => {
    if (!activeObservation && selectedMarker) {
      selectMarker(null, mapRef);
    }
  }, [activeObservation]);

  // update selected marker style
  useEffect(() => {
    if (selectedMarker) {
      const marker = selectedMarker.marker;
      if (marker) {
        mapRef.strategy.updateMarker(marker, {
          style: stationMarkerConfigurator(selectedMarker.data, mapRef, true)
        });
      }
    }
  }, [selectedMarker]);

  // update markers on unit toggle
  useEffect(() => {
    if (!mapRef) return;

    let markers: any[] = [];
    const stationsSource = mapRef.getSourceForId("station-obs");
    if (stationsSource) {
      markers = markers.concat(stationsSource.markers);
    }

    markers
      .filter(marker => marker !== undefined)
      .forEach((marker: any) => {
        const mapEl = marker.renderable;
        if (mapEl) {
          const selected = selectedMarker && selectedMarker.marker === mapEl;
          mapRef.strategy.updateMarker(mapEl, {
            style: stationMarkerConfigurator(marker.data, mapRef, selected)
          });
        }
      });
  }, [units, unitType]);

  return (
    <>
      <Map
        height={600}
        mapReadyHandler={(map, app) => setMapRef(map)}
        type="InteractiveMap"
        disableScrollWheel={true}
        config={{
          zoom: defaultZoom,
          layers: "radar-global:80"
        }}
      />
      <Absolute bottom="20px" width="100%" pointerEvents="none">
        <Flex justify="center">
          <Box p={0} rounded="full" backgroundColor="rgba(0,0,0,0.8)" pointerEvents="all">
            <UnitToggle
              p="3px"
              variantColor={theme.components.home.unitToggle.variantColor}
              context="components.home.unitToggle"
            />
          </Box>
        </Flex>
      </Absolute>
      {activeObservation && (
        <MapPanel
          observation={activeObservation}
          url={buildStationPath("dashboard", activeObservation.id)}
          handleCloseClick={() => setActiveObservation(null)}
        />
      )}
    </>
  );
};

type HeroButtonProps = {
  link: Link;
  user: User;
  clickHandler: (url: string) => void;
  styleProps?: any;
};
/**
 * Includes logic to display based on auth/unauth which can be configured in
 * Prismic.
 * @param {HeroButtonProps} props.
 * @returns {React.ReactElement}
 */
const HeroButton: React.FC<HeroButtonProps> = (props): React.ReactElement => {
  const { link, user, clickHandler } = props;
  const Display: React.FC<HeroButtonProps> = ({ link, clickHandler, styleProps }) => {
    return (
      <Button
        key={link.url}
        variant={ButtonVariant[link.style as ButtonVariantKey]}
        variantColor={ButtonVariantColor[link.style as ButtonVariantKey]}
        onClick={() => clickHandler(link.url)}
        mr={styleProps.mr}
      >
        {link.text}
      </Button>
    );
  };

  if (
    (link.displayRule === LinkDisplayRuleEnum.Unauthenticated && user.isAuthenticated === true) ||
    (link.displayRule === LinkDisplayRuleEnum.Authenticated && user.isAuthenticated === false)
  ) {
    return null;
  }
  if (link.displayRule !== LinkDisplayRuleEnum.Everyone) {
    return (
      <ClientRender>
        <Display {...props} />
      </ClientRender>
    );
  }
  return <Display {...props} />;
};

function HomepageDisplay(props) {
  const { homepage } = useQuery();

  const homepageModel = new HomepageModel(homepage);
  const user = useUser();
  useMessages();
  // TODO: remove when added to SDK
  const defaultZoom = 11;

  const handleClick = (url: string) => {
    navigate(url);
  };

  return (
    <PageLayout
      page={homepageModel}
      metaTitle={homepageModel.metaTitle}
      metaDescription={homepageModel.metaDescription}
      showTitleSuffix={false}
      headerProps={{
        position: "relative",
        bg: "transparent",
        variant: "light",
        includeLogo: false,
        includeSearch: false,
        pb: 0,
        mb: 0,
        zIndex: 2,
        context: "home"
      }}
      contentProps={{
        px: 0,
        bg: "#1a415f"
      }}
      background={{
        component: <HeroBackground />,
        height: ["586px", null, "650px"],
        width: "1550px"
      }}
    >
      <Box
        position="relative"
        left="0"
        right="0"
        margin="0 auto"
        top={["-50px", null, "-100px"]}
        color="brand.white.base"
        height={[500, null, 500]}
        zIndex={0}
        overflow="hidden"
      >
        <Section>
          <Grid position="relative" zIndex={2}>
            {homepageModel.has("hero") && (
              <>
                <Hide mobile>
                  <Row>
                    <Col>
                      <Box mt={[null, null, null, null, "-70px"]} mb={4}>
                        <Logo height={60} variantColor="light" includeAeris={true} />
                      </Box>
                    </Col>
                  </Row>
                  <Row mt={5}>
                    <Col lg={5} md={8}>
                      {homepageModel.hero.has("title") && (
                        <Heading as="h1" variant="largeHero">
                          {homepageModel.hero.title}
                        </Heading>
                      )}
                      {homepageModel.hero.has("subtitle") && (
                        <Heading as="h3" mt={4} variant="title1" fontWeight="normal">
                          {homepageModel.hero.subtitle}
                        </Heading>
                      )}
                      {homepageModel.hero.has("links") ? (
                        <ButtonGroup spacing={2} mt={4}>
                          {homepageModel.hero.links.map((link: Link) => (
                            <HeroButton
                              link={link}
                              user={user}
                              clickHandler={handleClick}
                              key={link.text}
                              styleProps={{ mr: 2 }}
                            />
                          ))}
                        </ButtonGroup>
                      ) : null}
                    </Col>
                  </Row>
                </Hide>
                <Hide tablet desktop>
                  <Row>
                    <Col>
                      <Flex justify="center" mt={40} mb={90}>
                        <HomeLogoLink isActive={true} height={50} />
                      </Flex>
                      {homepageModel.hero.has("title") && (
                        <Heading as="h1" variant="largeHero" textAlign="center">
                          {homepageModel.hero.title}
                        </Heading>
                      )}
                      <Button
                        mt={5}
                        width="100%"
                        height={12}
                        variantColor="light"
                        onClick={() => navigate("/map")}
                      >
                        {messageStore.autosuggest_station_map_link_text}
                      </Button>
                    </Col>
                  </Row>
                </Hide>
              </>
            )}
          </Grid>
        </Section>
      </Box>
      <Box>
        <Hide mobile>
          <Box position="relative">
            <SearchField />
            <HomepageMap defaultZoom={defaultZoom} />
          </Box>
        </Hide>
        {homepageModel.has("features") && <Blocks features={homepageModel.features}></Blocks>}
      </Box>
    </PageLayout>
  );
}
export default function Homepage(props: HomepageProps): React.ReactElement {
  return (
    <Providers>
      <HomepageDisplay {...props} />
    </Providers>
  );
}
