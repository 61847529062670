import schema from "./schema";
import * as yup from "yup";
import _get from "lodash.get";
import Model from "../Model";
import Hero from "../hero/";
import Features from "../features";
export default class Homepage extends Model {
  protected schema: yup.ObjectSchema = schema;
  public hero: Hero;
  public features: Features;
  constructor(protected data: object) {
    super(data);
    const hero = new Hero(data);
    const features = new Features(data);
    this.hero = hero.isValid ? hero : null;
    this.features = features.isValid ? features : null;
  }
  public get metaTitle() {
    return this.get("meta_title");
  }
  public get metaDescription() {
    return this.get("meta_description");
  }
}
