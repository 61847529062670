import Model from "../Model";
import Link from "../link/";
import schema from "./schema";
import * as yup from "yup";
import _get from "lodash.get";
import _isNil from "lodash.isnil";

export default class Feature extends Model {
  protected schema: yup.ObjectSchema = schema;
  public link: Link = null;
  constructor(apiData) {
    super(apiData);
    if (
      (_isNil(_get(apiData, "feature_link.url", null)) !== true &&
        _get(apiData, "feature_link.url") !== "") ||
      _get(apiData, "feature_link.link_type") === "Document"
    ) {
      this.link = new Link({
        link: apiData.feature_link,
        link_text: apiData.feature_link_text,
        link_style: apiData.feature_link_style
      });
    }
  }
  get category() {
    const category = this.get("category");
    if (category === null || category === "None") {
      return null;
    }
    return category;
  }
  get alignment() {
    return this.get("feature_alignment").toLowerCase();
  }
  get title() {
    return this.get("feature_title");
  }
  get content() {
    return this.get("feature_content");
  }
  get imageFluid() {
    return this.get("feature_image.localFile.childImageSharp.fluid");
  }
  get imageUrl() {
    return this.get("feature_image.url");
  }
  get imageAlt() {
    return this.get("feature_image.alt");
  }
}
