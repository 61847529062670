import Model from "../Model";
import schema from "./schema";
import * as yup from "yup";
import Link from "../link";
import Links from "../navigation/";
import _isNil from "lodash.isnil";

export default class Hero extends Model {
  protected schema: yup.ObjectSchema = schema;
  navigation: Links = null;

  get title() {
    return this.get("hero_title");
  }

  get subtitle() {
    return this.get("hero_subtitle");
  }
  get links() {
    let items = this.get("body[0].links");

    if (items === null) {
      items = this.get("body[0].items");
      if (items === null) {
        return null;
      }
    }

    const links = items.map(item => new Link(item));
    return links;
  }
}
