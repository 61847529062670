import { object, string, array } from "yup";

export default object()
  .required()
  .noUnknown()
  .shape({
    hero_title: string(),
    hero_description: string(),
    body: array().of(
      object().shape({
        links: array(),
        items: array()
      })
    )
  });
