import React, { useEffect, useState } from "react";
import {
  Box,
  Section,
  Grid,
  Col,
  Row,
  ButtonLink,
  Heading,
  Flex,
  Text,
  Image
} from "pws-design-system/design-system";

import { List, ListItem } from "@chakra-ui/core";
import { RichText, Elements } from "prismic-reactjs";
import FeaturesModel from "../../../../models/prismic/features";
import PrismicLink from "../../../common/components/prismic-link/";
import PrismicImg from "../../../common/components/prismic-img/";
import { css } from "@emotion/react";
import { matchesMediaQuery } from "../../../common/components/responsive-render/ResponsiveRender";
import { ThemeContainer, useTheme } from "../../../common/hooks/useTheme";

interface BlocksProps {
  features: FeaturesModel;
}

function htmlSerializer(type, element, content, children, key) {
  switch (type) {
    case Elements.oList:
      return (
        <List styleType="decimal" my={4}>
          {children}
        </List>
      );
    case Elements.list:
      return (
        <List styleType="square" my={4}>
          {children}
        </List>
      );
    case Elements.listItem:
      return <ListItem>{children}</ListItem>;
    case Elements.paragraph:
      return (
        <Text mb={4} lineHeight="tall">
          {children}
        </Text>
      );
    case Elements.heading3:
      return (
        <Heading as="h3" size="xl">
          {children}
        </Heading>
      );
    case Elements.heading4:
      return (
        <Heading as="h4" size="lg">
          {children}
        </Heading>
      );
    case Elements.heading5:
      return (
        <Heading as="h5" size="md">
          {children}
        </Heading>
      );
    case Elements.heading6:
      return (
        <Heading as="h6" size="sm">
          {children}
        </Heading>
      );
    default:
      return null;
  }
}

const htmlSerializerWithTheme = theme => {
  function htmlSerializer(type, element, content, children, key) {
    switch (type) {
      case Elements.oList:
        return (
          <List styleType="decimal" my={4}>
            {children}
          </List>
        );
      case Elements.list:
        return (
          <List styleType="square" my={4}>
            {children}
          </List>
        );
      case Elements.listItem:
        return <ListItem>{children}</ListItem>;
      case Elements.paragraph:
        return (
          <Text mb={4} lineHeight="tall" color={theme.components.features.color}>
            {children}
          </Text>
        );
      case Elements.heading2:
        return (
          <Heading as="h2" size="xl" color={theme.components.features.color}>
            <Text>{children}</Text>
          </Heading>
        );
      case Elements.heading3:
        return (
          <Heading as="h3" size="xl" color={theme.components.features.color}>
            <Text>{children}</Text>
          </Heading>
        );
      case Elements.heading4:
        return (
          <Heading as="h4" size="lg" color={theme.components.features.color}>
            {children}
          </Heading>
        );
      case Elements.heading5:
        return (
          <Heading as="h5" size="md" color={theme.components.features.color}>
            fsad
            {children}
          </Heading>
        );
      case Elements.heading6:
        return (
          <Heading as="h6" size="sm" color={theme.components.features.color}>
            fasd
            {children}
          </Heading>
        );
      default:
        return null;
    }
  }
  return htmlSerializer;
};

const FeatureContent = ({ feature }: any): React.ReactElement => {
  const { theme, setTheme } = ThemeContainer.useContainer();

  return (
    <Flex wrap="wrap" pl={[null, null, 5]}>
      <Box width="100%">
        {feature.has("category") && (
          <Text
            variant="caption1"
            fontWeight="bold"
            color="brand.green.base"
            textTransform="uppercase"
            mb={2}
          >
            {feature.category}
          </Text>
        )}
        {feature.has("title") && (
          <Heading as="h3" color={theme.components.features.color}>
            {feature.title}
          </Heading>
        )}
        {feature.has("content") && (
          <Box my={4} lineHeight="tall">
            {RichText.render(
              feature.content.raw,
              <div>{RichText.render(feature.content)}</div>,
              htmlSerializerWithTheme(theme)
            )}
          </Box>
        )}
        {feature.has("link") && (
          <PrismicLink
            link={feature.link}
            Tag={ButtonLink}
            rightIcon="arrow-right"
            width={["100%", null, "auto"]}
            height={12}
            variant={feature.link.style === "Secondary" ? "outline" : "solid"}
          />
        )}
      </Box>
    </Flex>
  );
};

const FeatureImage = ({ feature, side = "left", ...rest }: any): React.ReactElement => {
  const margins = [null, null, "calc(50% - 50vw)"];

  if (side === "left") {
    rest.ml = margins;
  } else {
    rest.mr = margins;
  }

  return (
    <Box mb={[4, null, 0]} {...rest}>
      <PrismicImg
        src={feature.imageUrl}
        fluid={feature.imageFluid}
        alt={`${feature.imageAlt}`}
        objectFit="contain"
        objectPosition={side === "left" ? "100% 0" : "0 50%"}
        style={{
          width: "auto",
          height: "auto",
          maxHeight: "500px",
          maxWidth: "100%"
        }}
      />
    </Box>
  );
};

const Feature = ({ feature, position }: any): React.ReactElement => {
  const i = 0;
  const style = matchesMediaQuery("mobile") === true ? { order: -1 } : null;
  return (
    <Section py={5}>
      <Grid>
        {position === "left" ? (
          <Row align="center">
            <Col
              md={5}
              css={css`
                order: -1;
              `}
            >
              <FeatureImage side={position} feature={feature} />
            </Col>
            <Col md={6}>
              <FeatureContent feature={feature} />
            </Col>
          </Row>
        ) : (
          <Row justify="space-between" align="center">
            <Col tablet={false} desktop={false}>
              <FeatureImage side={position} feature={feature} />
            </Col>
            <Col md={6}>
              <FeatureContent feature={feature} />
            </Col>
            <Col md={5} mobile={false}>
              <FeatureImage side={position} feature={feature} />
            </Col>
          </Row>
        )}
      </Grid>
    </Section>
  );
};

export default function Features(props: BlocksProps): React.ReactElement {
  const { theme, setTheme } = ThemeContainer.useContainer();
  const { features } = props;

  if (features.has("models") === false) {
    return null;
  }

  function getPosition(feature: Feature, i: number) {
    if (feature.has("alignment") && feature.alignment === "auto") {
      return i % 2 === 0 ? "left" : "right";
    } else if (feature.has("alignment")) {
      return feature.alignment;
    }
    return "left";
  }

  function handleClick() {
    if (theme.name === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }

  return (
    <Box bg={theme.components.features.bg}>
      {features.models.map((feature, i) => (
        <Feature feature={feature} key={feature.uuid} position={getPosition(feature, i)} />
      ))}
    </Box>
  );
}
