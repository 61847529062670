import Model from "../Model";
import * as yup from "yup";
import Feature from "../feature";
import _isNil from "lodash.isnil";
import _get from "lodash.get";
import schema from "./schema";

export default class Features extends Model {
  protected schema: yup.ObjectSchema = schema;
  models: Feature[] = null;
  constructor(apiData) {
    super(apiData);
    if (_isNil(apiData.feature) !== true) {
      this.models = apiData.feature
        .map(datum => new Feature(datum))
        .filter(feature => feature.isValid);
    }
  }
}
