import { object, string, array, number } from "yup";

export default object().shape({
  category: string().required(),
  feature_title: string().required(),
  feature_content: object()
    .required()
    .shape({
      raw: array()
        .required()
        .of(
          object().shape({
            text: string(),
            type: string().required(),
            spans: array().of(
              object().shape({
                end: number().required(),
                start: number().required(),
                type: string().required()
              })
            )
          })
        )
    })
});
